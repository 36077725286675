<template>
  <div>
    <v-btn small text color="red" @click="cancelTripSheet">Cancel</v-btn>
<!--    <Loading v-if="loading"></Loading>-->
    <confirm ref="confirm"></confirm>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import axios from "@/axios/axios-global";

export default {
  name: "CancelTripSheet",
  components: {Confirm, Loading},
  props: {
    trip: [Object, String]
  },
  data: () => {
    return {
      loading: false,
    }
  },
  methods: {
    async cancelTripSheet() {
      let confirmTitle = "Confirm Cancel Trip Sheet";
      let confirmText = "Please confirm to cancel trip sheet";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        // this.loading = true
        try {
          const payload = {
            tripId: this.trip.bigId,
            key: this.trip.tripSheetKey
          }
          const response = await axios.patch('backend/tripSheetRemove',payload)
          console.log(response)
          // this.loading = false
          if(response.status === 200) {
            this.$emit('on-cancel')
          }
        } catch (error) {
          // this.loading = false
          console.log('====================error=====================')
        }
      }


    }
  }
}
</script>

<style scoped>

</style>