<template>
  <div class="filter-outer-blk">
    <div class="filter-search-blk">
      <v-text-field
          v-model="searchValue"
          :placeholder= placeHolder
          :label= placeHolder
          dense
          outlined
          hide-details
          @input="emitFilters"
          clearable
      >

      </v-text-field>
    </div>
    <div class="filter-blk" v-if="dateRange === false && filterData === 'toBeAssigned'">
      <v-menu
          v-model="dateModel"
          :nudge-right="40"
          :nudge-top="20"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              v-model="dateText"
              outlined
              label="Date"
              readonly
              dense
              v-on="on"
              hide-details
          >
          </v-text-field>

        </template>
        <v-date-picker v-model="date" @change="emitFilters" color="#000000"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="filter-blk" v-if="dateRange === true && filterData === 'trips'">
      <v-menu
          v-model="dateRangeModal"
          :nudge-right="40"
          :nudge-top="20"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
      >
        <template v-slot:activator="{on}">

          <v-text-field
              v-model="dateRangeText"
              outlined
              label="Date Range"
              readonly
              dense
              v-on="on"
              hide-details
          >
          </v-text-field>

        </template>

        <v-date-picker v-model="dateRangeValue"  range :max="today" color="#000000"></v-date-picker>

      </v-menu>
    </div>
<!--    <div class="filter-blk" v-if="filterData === 'toBeAssigned'">-->
<!--      <v-autocomplete-->
<!--          v-model="state"-->
<!--          placeholder="State"-->
<!--          label="State"-->
<!--          dense-->
<!--          outlined-->
<!--          hide-details="auto"-->
<!--          :items="filters.state"-->
<!--          item-text="name"-->
<!--          return-object-->
<!--          clearable-->
<!--      >-->

<!--      </v-autocomplete>-->
<!--    </div>-->
<!--    <div class="filter-blk" v-if="filterData === 'toBeAssigned'">-->
<!--      <v-autocomplete-->
<!--          v-model="city"-->
<!--          placeholder="City"-->
<!--          label="City"-->
<!--          dense-->
<!--          outlined-->
<!--          hide-details="auto"-->
<!--          :items="state.city"-->
<!--          item-text="name"-->
<!--          return-object-->
<!--          clearable-->
<!--      >-->

<!--      </v-autocomplete>-->
<!--    </div>-->
    <div class="reset-button-blk">
      <v-btn text color="#ff9500" @click="resetFilters"><i class="fas fa-redo"></i></v-btn>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import * as moment from 'moment'

export default {
  name: "Filters",
  props: {
    dateRange: Boolean,
    placeHolder: String,
    filterData : String,
  },
  data: () => {
    return {
      filters: '',
      searchValue: '',
      state: '',
      city: '',

      dateModel: false,
      date: moment().format('YYYY-MM-DD'),

      dateRangeModal: false,
      dateRangeValue: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      today: moment().add(7, 'days').format('YYYY-MM-DD'),
    }
  },
  watch: {
    searchValue() {
      if(this.searchValue === null) {
        this.searchValue = ''
      }
      this.emitFilters()
    },
    state() {
      if (this.state === null) {
        this.state = ''
        this.city = ''
      }
      this.emitFilters()
    },
    city() {
      if (this.city === null) {
        this.city = ''
      }
      this.emitFilters()
    },
    dateRangeValue() {
      if (this.dateRangeValue[0]) {
        if (this.dateRangeValue[1]) {
          if (this.dateRangeValue[0] > this.dateRangeValue[1]) {
            this.startDate = moment(this.dateRangeValue[1]).format('YYYY-MM-DD')
            this.endDate = moment(this.dateRangeValue[0]).format('YYYY-MM-DD')
          } else {
            this.startDate = moment(this.dateRangeValue[0]).format('YYYY-MM-DD')
            this.endDate = moment(this.dateRangeValue[1]).format('YYYY-MM-DD')
          }
          this.emitFilters();
          this.dateRangeModal = false
        }
        // else {
        //   this.startDate = moment(this.dateRangeValue[0]).format('YYYY-MM-DD')
        //   this.endDate = moment().format('YYYY-MM-DD')
        // }
      }

    }
  },
  mounted() {
    // this.getFilters()
  },
  methods: {
    async getFilters() {
      const response = await axios.post('/router/filterData')
      this.filters = response.data
    },
    resetFilters() {
      this.state = ''
      this.city = ''
      this.searchValue = ''
      this.date = moment().format('YYYY-MM-DD')
      this.dateRangeValue = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      this.startDate = moment().format('YYYY-MM-DD')
      this.endDate = moment().format('YYYY-MM-DD')
      this.emitFilters();
    },
    emitFilters() {
      let payload = {
        start: this.filterData === 'trips' ? this.startDate : undefined,
        end: this.filterData === 'trips' ? this.endDate : undefined,
        date: this.filterData === 'toBeAssigned'?this.date : undefined,
        state: this.state !== '' ?this.state.id : undefined,
        searchValue: this.searchValue,
        city: this.city !== '' ?this.city.id : undefined
      }
      this.$emit('get-filter',payload)
    }
  },
  computed: {
    dateText() {
        return moment(this.date).format('LL')
    },
    dateRangeText() {
      let start = ''
      let end = ''
      if (this.dateRangeValue[0] > this.dateRangeValue[1]) {
        start = moment(this.dateRangeValue[1]).format('MMM DD')
        end = moment(this.dateRangeValue[0]).format('MMM DD')
      } else {
        start = moment(this.dateRangeValue[0]).format('MMM DD')
        end = moment(this.dateRangeValue[1]).format('MMM DD')
      }
      return `${start} ~ ${end}`

    },

  }
}
</script>

<style scoped lang="scss">
.filter-outer-blk {
  display: flex;
  align-items: center;
  width: 100%;

  .filter-search-blk {
    width: 20%;
  }

  .filter-blk {
    width: 15%;
    margin-left: 10px;
  }
}
::v-deep .v-text-field {
  font-size: 13px;
}
 .v-list {
  padding: 3px 0;
}

</style>