<template>
  <v-card class="receipt-add-modal">
    <div class="receipt-blk">
      <span class="receipt-header-modal">Parking Receipt</span>
      <div class="input-field-modal">
        <div class="input-modal">
          <v-text-field
              v-model="receiptAmount"
              placeholder="Amount"
              label="Amount"
              dense
              outlined
              prepend-inner-icon="₹"
              hide-details
          >

          </v-text-field>
        </div>
        <div class="input-modal">
          <input ref="receiptUpload" class="input-blk" @change="selectData" type="file" accept="image/*">
          <v-btn color="#5891df" @click="$refs.receiptUpload.click()" v-if="fileName === ''">Add Receipt</v-btn>
          <div v-if="fileName !== ''" class="file-name-modal">
            <span>{{this.fileName}}</span>
            <i class="fas fa-times" @click="fileName = ''"></i></div>
        </div>
      </div>
      <div class="receipt-insert-button-modal">
        <v-btn color="#fa9e15" :disabled="fileName === '' || receiptAmount === ''" @click="addReceipt">Add Receipt</v-btn>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
    <Loading v-if="loading"></Loading>
  </v-card>
</template>

<script>
import axios from "@/axios/axios-global";
import Confirm from "@/components/common/Confirm";
import Loading from "@/components/common/Loading";

export default {
  name: "ReceiptInsertDialog",
  components: {Loading, Confirm},
  props: {
    tripId: Number
  },
  watch: {
    tripId() {
      this.fileName = ''
      this.receiptAmount = ''
    }
  },
  mounted() {
    this.fileName = ''
    this.receiptAmount = ''
  },
  data: () => {
    return {
      fileName: '',
      receiptAmount: '',
      receiptFile: '',

      loading: false,
    }
  },
  methods: {
    selectData(event) {
      let files = event.target.files
      this.fileName  = files[0].name
      this.receiptFile = files[0]
      this.$refs.receiptUpload.value = ''
      console.log(this.fileName)
    },
    async addReceipt() {
      let confirmTitle = "Confirm Add Parking Receipt";
      let confirmText = "Please confirm to Add Parking Receipt";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const payload = {
            tripId: this.tripId,
            parkingFee: this.receiptAmount
          }
          let formData = new FormData()
          formData.append('data',JSON.stringify(payload))
          formData.append('parkingReceipt',this.receiptFile)
          const response = await axios.patch('/backend/parkingReceiptInsert',formData)
          if(response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
            this.$emit('insert-receipt',response.data.data)
            this.receiptAmount = ''
            this.fileName = ''
            this.receiptFile = ''
          }
        }catch (error) {
          this.loading = false
        }
      }

    },
  }
}
</script>

<style scoped lang="scss">
.receipt-add-modal {
  border-radius: 0;
  padding: 10px;

  .receipt-blk {
    display: flex;
    flex-direction: column;
    width: 100%;

    .receipt-header-modal {
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #e0dede;
      width: 100%;
      padding: 5px 0;
    }

    .input-field-modal {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .input-modal {
        width: 48%;
        display: flex;
        align-items: center;

        .v-btn {
          margin-top: 2px;
        }
        .file-name-modal {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 7px;
          width: 100%;
        }
        span {
          color: #4e96be;
          margin-right: 5px;
          max-width: 94%;
          border-right: none;
        }
        .fas {
          cursor: pointer;
        }
      }
    }
    .receipt-insert-button-modal {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 10px;
      margin-top: 5px;
    }
    ::v-deep.v-icon.v-icon {
      font-size: 16px;
    }
  }
}

::v-deep .v-input__icon {
  width: 10px !important;
  min-width: 10px !important;
  margin-right: 5px;
}
</style>