<template>
  <div class="driver-details-modal" v-if="driverDetails.vehicles">
    <div class="driver-image-blk text-center" v-if="driverDetails.document">
      <img :src="driverDetails.image" class="image-blk">
    </div>
    <div class="name-blk text-center">
      <span>{{ driverDetails.name }}</span>
    </div>
    <div class="text-center">
      <span><i class="fas fa-phone-alt"></i>{{ driverDetails.mobile }}</span>
    </div>
    <div class="text-center color-gray-blk" v-if="driverDetails.document">
      <span v-if="driverDetails.document.licensefront.no"><b>Licence No:</b>{{ driverDetails.document.licensefront.no }}</span>
      <span v-else><b>Licence No:</b>No Data Added</span>
    </div>
    <div class="text-center color-gray-blk" v-if="driverDetails.document">
      <span><b>Licence Expiry Date:</b>{{ getExpDate(driverDetails.document.licensefront.expdate) }}</span>
    </div>
    <!--    <div v-if="driverDetails.vehicles">-->
    <div class="text-center vehicle-list-modal" v-if="driverDetails.vehicles.length > 0">
      <div class="vehicle-list-blk" v-for="(data,index) in driverDetails.vehicles" :key="index">
        <span>{{ data.vehicleno }}</span>
        <span class="margin-blk" @click="unlinkVehicle(data)"><i class="fas fa-minus-circle"></i></span>
      </div>
    </div>
    <!--    </div>-->
    <div class="text-center no-vehicle-modal" v-if="driverDetails.vehicles.length === 0">
      <span class="vehicle-text">No Vehicle Assigned</span>
    </div>

    <div class="edit-button-modal">
      <v-btn color="#e9bd00" @click="editDriverDetails">Edit</v-btn>
    </div>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import * as moment from "moment";
import Confirm from "@/components/common/Confirm";
export default {
  name: "DriverDetails",
  components: {Confirm, Loading},
  props: {
    driverDetails: Object
  },
  data: () => {
    return {
      baseUrl: 'https://taski2020.s3.ap-south-1.amazonaws.com/',

      loading: false
    }
  },
  mounted() {
    console.log(this.driverDetails)
  },
  methods: {
    async unlinkVehicle(vehicle) {
      let confirmTitle = "Confirm UnAssign";
      let confirmText = `Please Confirm to Unassign ${vehicle.vehicleno} from ${this.driverDetails.name}`;
      let confirmIcon = "fas fa-exclamation"
      if (await this.$refs.confirm.open(confirmTitle, confirmText,{
        icon:confirmIcon,
        color:'red',
      })) {
        this.loading = true
        try {
          const response = await axios.patch('/backend/vehicleUnAssign', {
            vehicleToken: vehicle.id,
            driverToken: this.driverDetails.token
          })
          console.log(response)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
            const index = this.driverDetails.vehicles.findIndex(item => item.id === vehicle.id)
            this.driverDetails.vehicles.splice(index, 1)
          }
        } catch (error) {
          this.loading = false
          this.$toasted.show(error.response.data, {
            position: "bottom-center",
            duration: 4000
          });
        }
      }
    },
    getExpDate(data) {
      if(data === '') {
        return 'No Data Added'
      }
      else {
        return moment(data).format('DD-MM-YYYY')
      }

    },
    editDriverDetails() {
      this.$emit('edit-driver', this.driverDetails)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./driverDetais";
</style>