<template>
  <div class="main-inner-blk">
    <v-card class="trip-filter-blk" elevation="3">
      <Filters :dateRange="true" :filterData="'trips'" @get-filter="getFilters" :placeHolder="'Search ID/User'"></Filters>
    </v-card>

    <div class="trip-list-blk" :class="{'no-trip-blk':tripList.length === 0}">
      <div v-if="tripList.length > 0">
        <div class="trip-list-content">
<!--          <span class="info-blk"><i class="fas fa-exclamation-circle"></i> Started and Ended Trips are shown in the list</span>-->
          <span class="ended-length-blk">Ended Trips: {{endedTripCount}}</span>
          <span class="started-length-blk">Started Trips: {{startedTripCount}}</span>
          <span class="trip-length-blk">Total Trips: {{tripList.length}}</span>

        </div>
        <TripsView :tripList="tripList" v-if="tripList.length > 0"></TripsView>
      </div>
      <div class="no-trip-blk" v-if="tripList.length === 0">
        <span>No Trips to Show</span>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import TripsView from "@/components/Trips/trips-view/TripsView";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import * as moment from "moment";

export default {
  name: "TripList",
  components: {Loading, TripsView, Filters},
  data: () => {
    return {
      tripList: [],

      loading: false,

      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),

      startedTripCount: 0,
      endedTripCount: 0
    }
  },
  mounted() {

    this.getData({start:this.startDate,end:this.endDate})
  },
  methods: {
    async getData(data) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.startedTripCount = 0
        this.endedTripCount = 0
        this.loading = true
        const response = await axios.post('/backend/tripList', {start: data.start, end: data.end, search: data.search})
        console.log(response)
        this.loading = false
        this.tripList = response.data.list
        if(this.tripList.length > 0) {
          this.tripList.map(data => {
            if(data.status.id === 7 || data.status.id === 8) {
              this.startedTripCount += 1
            }
            else if(data.status.id === 10 || data.status.id === 9) {
              this.endedTripCount += 1
            }
          })
        }
      },500)
    },
    getFilters(data) {
      this.getData({start:data.start,end:data.end,search:data.searchValue})
    }
  }
}
</script>

<style scoped lang="scss">
@import "./tripList";
</style>