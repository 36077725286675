<template>
  <div class="main-trip-list-blk">
    <div class="trip-header-blk">
      <span class="id-blk">ID</span>
      <span class="user-blk">Employee</span>
      <span class="driver-blk">Driver & Car</span>
      <span class="date-blk">Date</span>
      <span class="reading-blk">Readings</span>
      <span class="km-blk">Toll</span>
      <span class="time-blk">Fare</span>
    </div>
    <div class="trip-list-blk" v-for="(data,index) in tripList" :key="index">
      <div class="id-blk content-blk">
        <a @click="openDrawer(data.id)">{{ data.id }}</a>
        <span class="ended-status-blk status-blk" v-if="data.status.id === 10 || data.status.id === 9">Ended <i class="fas fa-check-circle" v-if="data.verified === 1" title="Verified"></i></span>
        <span class="started-status-blk status-blk" v-if="data.status.id === 7 || data.status.id === 8">Started</span>
      </div>
      <div class="user-blk content-blk">
        <span>{{ data.user.name }}</span>
        <span>{{ data.user.mobile }}</span>
        <span>{{ data.user.email }}</span>
        <span>{{ data.user.branch }}</span>
      </div>
      <div class="driver-blk content-blk">
        <span>{{ data.driver.name }}</span>
        <span>{{ data.driver.mobile }}</span>
        <span>{{ data.driver.vehicle }}</span>

      </div>
      <div class="date-blk content-blk">
        <span class="tooltip-blk"><strong>Start Date : </strong>{{ getDateTime(data.sTime) }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"
              class="tooltip-blk"><strong>End Date : </strong>{{ getDateTime(data.eTime) }}</span>

      </div>
      <div class="reading-blk content-blk">
        <span class="reading-modal"><strong>Start Reading :</strong><span class="reading-content-modal"
                                                                          @click="imageView(data.readings,index)"><u>{{
            data.readings.start
          }}</u></span></span>
        <span class="reading-modal" v-if="data.readings.end !== ''"><strong>End Reading : </strong><span
            class="reading-content-modal"
            @click="imageViewEnd(data.readings,index)"><u>{{ data.readings.end }}</u></span></span>
        <span class="reading-modal"
              v-if="data.readings.end !== ''"><strong>Travel KM : </strong>{{
            data.readings.end - data.readings.start
          }}</span>
      </div>
      <div class="km-blk content-blk">
        <div class="toll-modal">
          <div class="flex-blk" v-for="(item,index) in data.toll" :key="index">
            <v-chip color="green" label small v-if="item.status === 1" title="Approved">
              <span>₹ {{ item.amount }}</span>
            </v-chip>
            <v-chip v-if="item.status === 0" color="orange" label small title="Waiting for approval">
              <span>₹ {{ item.amount }}</span>
              <span @click="cancelToll(data,item)"><i class="fas fa-times"></i></span>
            </v-chip>
            <v-chip label small color="red" v-if="item.status === 2" title="Rejected">
              <span>₹ {{ item.amount }}</span>
            </v-chip>
          </div>
        </div>
        <v-btn x-small color="#facc15" @click="viewTollDialog(data)" v-if="data.verified !== 1">Add Toll</v-btn>
      </div>
      <div class="time-blk content-blk">

        <span v-if="data.status.id === 10 || data.status.id === 9"> <strong>Total Time :</strong> {{
            getTotalTime(data.driverCalculation.travelTime)
          }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Total KM :</strong> {{
            data.driverCalculation.travelKM
          }}</span>
        <span v-if="data.status.id === 7 || data.status.id === 8"><strong>Approved KM :</strong> {{
            data.driverCalculation.approved
          }}</span>

        <span class="driver-fare-blk" v-if="data.verified === 1">{{data.totalAmount}}</span>
        <!--        <span v-if="data.status.id === 10"><strong>Total Time :</strong> {{ getTotalTime(data.userCalculation.totalTime) }}</span>-->
      </div>
    </div>
    <div class="export-blk">

      <v-btn color="#3a854e" @click="exportReport">Export Report</v-btn>
    </div>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        @hide="hideImage"
    >

    </vue-easy-lightbox>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        width="1200"
    >
      <TripDetails :tripId="tripId" v-if="drawer"></TripDetails>
    </v-navigation-drawer>

    <v-dialog
        v-model="tollDialog"
        width="650"
    >
      <TollAdd :tripId="bigId" @insert-toll="insertToll" v-if="tollDialog"></TollAdd>
    </v-dialog>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import * as moment from 'moment'
import TripDetails from "@/components/Trips/trip-details/TripDetails";
import VueExcel from "@/components/common/VueExcel";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import TollAdd from "@/components/Trips/TollAdd";
import Confirm from "@/components/common/Confirm";

export default {
  name: "TripsView",
  components: {Confirm, TollAdd, Loading, TripDetails},
  props: {
    tripList: Array,
  },
  data: () => {
    return {
      visible: false,
      images: [],

      drawer: false,
      tripId: 0,
      openIndex: 0,

      loading: false,
      tollDialog: false,
      bigId: 0,
    }
  },
  methods: {
    // async selectTripSheet(data, event) {
    //   const files = event.target.files
    //   const fileImage = files[0]
    //   let formData = new FormData()
    //   formData.append('data', JSON.stringify({tripId: data.bigId}))
    //   formData.append('tripSheet', fileImage)
    //   this.loading = true
    //   const response = await axios.post('/backend/tripSheetUpload', formData)
    //   if (response.status === 200) {
    //     this.$toasted.show(response.data.message, {
    //       position: "bottom-center",
    //       duration: 4000
    //     });
    //     this.loading = false
    //     const index = this.tripList.findIndex(item => item.bigId === data.bigId)
    //     if (index !== -1) {
    //       this.tripList[index].tripSheet = response.data.url
    //     }
    //   }
    // },


    getDateTime(data) {
      return moment(data).format('DD-MM-YYYY LT')
    },
    getTotalTime(time) {
      let h = Math.floor(time / 60);
      let m = time % 60;
      h = h < 10 ? '' + h : h;
      m = m < 10 ? '' + m : m;
      return `${h} hrs ${m.toString().substring(0, 2)} mins`;
    },
    imageView(data) {
      this.visible = true
      this.images = []
      if (data.end !== '') {
        this.images = [{src:data.startImage,title:data.start}, {src:data.endImage,title:data.end}]
      } else {
        this.images = [{src:data.startImage,title:data.start}]
      }

    },
    imageViewEnd(data) {
      this.visible = true
      this.images = [{src:data.endImage,title:data.end}, {src:data.startImage,title:data.start}]
    },
    hideImage() {
      this.visible = false
    },
    openDrawer(id) {
      this.drawer = true
      this.tripId = id
    },
    viewTollDialog(data) {
      this.tollDialog = true
      this.bigId = data.bigId
    },
    exportReport() {
      try {
        let tripData = []
        let header = {
          tripId: 'Trip Id',
          user: 'User',
          userMobile: 'User Number',
          city: 'City',
          branch: 'Branch',
          driver: 'Driver',
          driverNumber: 'Driver Number',
          vehicleNo: 'Vehicle Number',
          sDate: 'Start Date',
          eDate: 'End Date',
          travelTime: 'Travel Time',
          startReading: 'Start Reading',
          endReading: 'End Reading',
          travelKm: 'Travel KM',
          toll: 'Toll',
          totalAmount: 'Amount'

        }
        tripData.push(header)
        let total = 0
        this.tripList.map(data => {
          let hour = Math.floor(data.driverCalculation.travelTime / 60);
          let minute = data.driverCalculation.travelTime % 60;
          hour = hour < 10 ? '' + hour : hour;
          minute = minute < 10 ? '' + minute : minute;
          let time = `${hour} hrs ${minute} mins`;
          let toll = 0
          if(data.totalAmount !== '') {
            total += parseFloat(data.totalAmount)
          }
          if(data.toll.length > 0) {
            data.toll.map((item) => {
              if(item.status === 1) {
                toll += item.amount
              }
            })
          }
          let object = {
            tripId: data.id,
            user: data.user.name,
            userMobile: data.user.mobile.toString(),
            city: data.city.name,
            branch: data.user.branch,
            driver: data.driver.name,
            driverNumber: data.driver.mobile.toString(),
            vehicleNo: data.driver.vehicle,
            sDate: moment(data.sTime).format('DD-MM-YYYY LT'),
            eDate: moment(data.eTime).format('DD-MM-YYYY LT'),
            travelTime: data.status.id === 10 || data.status.id === 9 ? time : '__________',
            startReading: data.readings.start,
            endReading: data.status.id === 10 || data.status.id === 9 ? data.readings.end : '__________',
            travelKm: data.status.id === 10 || data.status.id === 9 ? data.driverCalculation.travelKM : '__________',
            toll: data.status.id === 10 || data.status.id === 9 ? toll : '__________',
            totalAmount: data.totalAmount,
          }
          tripData.push(object)
        })

        tripData.push({})
        tripData.push({toll: 'Total Amount',totalAmount: total})
        VueExcel.methods.downloadExcelSheet(tripData, 'Report', {skipHeader: true})
      } catch (e) {
        console.log(e)
      }
    },
    async cancelToll(data, toll) {
      let confirmTitle = "Confirm Cancel";
      let confirmText = "Please confirm to Cancel this Toll";
      let confirmIcon = "fas fa-exclamation";
      if (await this.$refs.confirm.open(confirmTitle, confirmText, {
        icon: confirmIcon,
        color: 'red',
      })) {
        this.loading = true
        try {
          const index = this.tripList.findIndex(trip => trip.id === data.id)
          if (index !== -1) {
            const tollIndex = this.tripList[index].toll.findIndex(item => item.id === toll.id)
            if (tollIndex !== -1) {
              this.tripList[index].toll.splice(tollIndex, 1)
            }
          }
          const response = await axios.post('/backend/tollRemove', {tripId: data.bigId, toll: this.tripList[index].toll,tollId: toll.id})
          console.log(response)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
          } else if (response.status === 206) {
            this.loading = false
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000
            });
          }
        } catch (error) {
          this.loading = false
          this.$toasted.show(error, {
            position: "bottom-center",
            duration: 4000
          });
        }
      }
    },
    insertToll(data) {
      console.log(data)
      this.tollDialog = false
      const index = this.tripList.findIndex(trip => trip.bigId === this.bigId)
      if (index !== -1) {
        this.tripList[index].toll.unshift(data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./tripView";
</style>