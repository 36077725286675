<template>
  <v-card class="start-end-add-modal">
    <div class="start-end-blk">
      <span class="start-end-header-modal">{{ this.status }} Trip</span>
      <div class="start-end-image" v-if="fileUrl !== ''">
        <img :src="fileUrl" class="image">
      </div>
      <div class="input-field-modal">
        <div class="input-modal">
          <v-file-input
              v-model="readingImage"
              :placeholder="`${this.status} Image`"
              :label="`${this.status} Image`"
              dense
              outlined
              hide-details
              accept="image/*"
              @change="chooseImage"
          >

          </v-file-input>
        </div>
        <div class="input-modal">
          <v-text-field
              v-model="reading"
              :placeholder="`${this.status} Reading`"
              :label="`${this.status} Reading`"
              outlined
              dense
              hide-details
          >

          </v-text-field>
        </div>

      </div>
      <div class="start-end-button-blk">
        <v-btn color="#016401" @click="startEndTrip" :disabled="readingImage === null">{{ status }} Trip</v-btn>
        <v-btn color="#ab0606" @click="closeDialog">Cancel</v-btn>
      </div>
    </div>
    <confirm ref="confirm"></confirm>
    <Loading v-if="loading"></Loading>
  </v-card>
</template>

<script>
import axios from "@/axios/axios-global";
import Confirm from "@/components/common/Confirm";
import Loading from "@/components/common/Loading";

export default {
  name: "StartEndDialog",
  components: {Loading, Confirm},
  props: {
    status: String,
    bigId: Number,
  },
  data: () => {
    return {
      readingImage: null,
      reading: '',
      fileUrl: '',
      loading: false,
    }
  },
  methods: {
    chooseImage() {
      this.fileUrl = URL.createObjectURL(this.readingImage)
    },
    async startEndTrip() {
      let confirmTitle = `Confirm ${this.status} odo meter`;
      let confirmText = `Please confirm to insert ${this.status} odo meter`;
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const payload = {
            tripId: this.bigId,
            type: this.status,
            reading: parseFloat(this.reading)
          }
          console.log(payload)
          const formData = new FormData()
          formData.append('param', JSON.stringify(payload))
          formData.append('image', this.readingImage)
          const response = await axios.post('/backend/trip', formData)
          console.log(response)
          this.loading = false
          if(response.status === 200) {
            this.$emit('close-dialog')
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
          } else {
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
          }
        } catch (error) {
          console.log(error)
          this.loading = false
        }
      }

    },
    closeDialog() {
      this.readingImage = null
      this.fileUrl = ''
      this.reading = ''
      this.$emit('close-dialog')
    }
  },
  watch: {
    readingImage() {
      if (this.readingImage === null) {
        this.fileUrl = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.start-end-add-modal {
  border-radius: 0;
  padding: 10px;
  text-transform: capitalize;

  .start-end-blk {
    display: flex;
    flex-direction: column;
    width: 100%;

    .start-end-header-modal {
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #e0dede;
      width: 100%;
      padding: 5px 0;

    }

    .start-end-image {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px;

      .image {
        max-width: 100%;
        max-height: 300px;
        object-fit: cover;
      }
    }

    .input-field-modal {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .input-modal {
        width: 48%;
        display: flex;
        align-items: center;

      }
    }

    .start-end-button-blk {
      display: flex;
      flex-direction: row-reverse;
      margin: 10px;

      ::v-deep .v-btn {
        color: white;
        margin: 0 8px;
        font-weight: bold;
      }
    }

    ::v-deep.v-icon.v-icon {
      font-size: 16px;
    }
  }
}
</style>