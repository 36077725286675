<template>
  <div class="main-trip-list-blk" v-bind:style="{ height: elementHeight + 'px' }" ref="element">
    <div class="trip-header-blk">
      <span class="id-blk">ID</span>
      <span class="user-blk">Employee</span>
      <span class="driver-blk">Driver & Car</span>
      <span class="date-blk">Date</span>
      <span class="reading-blk">Readings</span>
      <span class="time-blk">Fare</span>
      <!-- <span class="receipt-blk">Parking Receipt</span> -->
      <span class="km-blk">Toll</span>
      <span class="actions">Actions</span>
    </div>
    <div class="trip-list-blk" v-for="(data,index) in tripList" :key="index">
      <div class="id-blk content-blk">
        <a class="id-section" @click="openDrawer(data.id)">{{ data.id }}</a>
<!--        <span class="ended-status-blk status-blk" v-if="data.status.id === 10 || data.status.id === 9">Ended <i-->
<!--            class="fas fa-check-circle" v-if="data.verified === 1" title="Verified"></i></span>-->
<!--        <span class="started-status-blk status-blk" v-if="data.status.id === 7 || data.status.id === 8">Started</span>-->
      </div>
      <div class="user-blk content-blk">
        <span>{{ data.user.name }}</span>
        <span>{{ data.user.mobile }}</span>
        <span>{{ data.user.email }}</span>
        <span>{{ data.user.branch }}</span>
        <div class="additional-row">
          <img src="../../../assets/assets/images/vip-icon.png" v-if="data.isVip">
          <img src="../../../assets/assets/images/woman-avatar.svg" v-if="data.gender === 'Female'">
        </div>
      </div>
      <div class="driver-blk content-blk">
        <span>{{ data.driver.name }}</span>
        <span>{{ data.driver.mobile }}</span>
        <span>{{ data.driver.vehicle }}</span>

      </div>
      <div class="date-blk content-blk">
        <span class="tooltip-blk" v-if="data.sTime"><strong>Start Date</strong></span>
        <span>{{ getDateTime(data.sTime) }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"
              class="tooltip-blk"><strong>End Date</strong></span>
        <span>{{ getDateTime(data.eTime) }}</span>

      </div>
      <div class="reading-blk content-blk">
        <span v-if="data.readings.start"><strong>Start Reading</strong></span>
        <span class="reading-modal"
              @click="imageView(data.readings,index)" v-if="data.readings.start && data.readings.start !== ''"><u>{{ data.readings.start }}</u></span>
        <span v-if="data.readings.end && data.readings.end !== ''"><strong>End Reading</strong></span>
        <span class="reading-modal"
            @click="imageViewEnd(data.readings,index)" v-if="data.readings.end && data.readings.end !== ''"><u>{{ data.readings.end }}</u></span>
        <span
              v-if="data.readings.end && data.readings.end !== ''"><strong>Travel KM</strong></span>
        <span class="reading-modal" v-if="data.readings.end && data.readings.end !== ''">{{ data.readings.end - data.readings.start }}</span>

      </div>

      <div class="time-blk content-blk">

        <span v-if="data.status.id === 10 || data.status.id === 9"> <strong>Total Time</strong></span>
        <span v-if="data.status.id === 10 || data.status.id === 9">{{getTotalTime(data.driverCalculation.travelTime) }}</span>
        <span v-if="data.status.id === 10 || data.status.id === 9"><strong>Total KM</strong> </span>
        <span v-if="data.status.id === 10 || data.status.id === 9">{{data.driverCalculation.travelKM}}</span>
        <!--        <span v-if="data.status.id === 7 || data.status.id === 8"><strong>Approved KM :</strong> {{data.driverCalculation.approved}}</span>-->

        <span class="driver-fare-blk" v-if="data.verified === 1">{{ data.totalAmount }}</span>
        <!--        <span v-if="data.status.id === 10"><strong>Total Time :</strong> {{ getTotalTime(data.userCalculation.totalTime) }}</span>-->
      </div>

      <!-- <div class="receipt-blk content-blk">
        <ReceiptView :trip="data" ></ReceiptView>
      </div> -->

      <div class="km-blk content-blk">
        <div class="toll-modal">
<!--          {{data.toll[0]}}-->
          <div class="flex-blk" v-for="(toll,index1) in data.toll" :key="index1">
            <v-chip color="green" label small v-if="toll.status === 1" title="Approved">
              <span>₹ {{ toll.amount }}</span>
            </v-chip>
            <v-chip v-if="toll.status === 0" color="orange" label small title="Waiting for approval">
              <span>₹ {{ toll.amount }}</span>
              <span @click="cancelToll(data,toll)"><i class="fas fa-times"></i></span>
            </v-chip>
            <v-chip label small color="red" v-if="toll.status === 2" title="Rejected">
              <span>₹ {{ toll.amount }}</span>
            </v-chip>
          </div>
        </div>
        <v-btn x-small color="#facc15" @click="viewTollDialog(data)" v-if="data.verified !== 1">Add Toll</v-btn>
      </div>
      <div class="actions content-blk">
        <span :style="{'color': data.status.colour,'font-weight':'bold'}">{{data.status.status}}</span>
        <div class="details-button-blk" v-if="data.status.id === 4 || data.status.id === 5 || data.status.id === 6">
          <v-btn small color="#3a854e" @click="tripAction(data,index,'start')">Start odo Meter</v-btn>
        </div>

        <v-btn v-if="[4,5,6].includes(data.status.id)" color="#ab0606" @click="tripAction(data,index,'end')" small> End Odo
          Meter
        </v-btn>
        <v-btn @click="uploadTripSheet(data)" color="blue" small text style="text-decoration: underline" v-if="data.verified !== 1 &&  data.tripSheet === '' ">{{ 'Upload Trip sheet' }}</v-btn>
        <v-btn  small text><a :href="data.tripSheet" style="color:#fb8c00;font-size: 12px" v-if="data.tripSheet !== '' " target="_blank">View Trip Sheet</a></v-btn>
        <CancelTripSheet :trip="data" @on-cancel="onCancel" v-if="data.verified !== 1 && data.tripSheet !== ''"></CancelTripSheet>
      </div>
    </div>
    <div class="export-blk">

      <v-btn color="#3a854e" @click="exportReport">Export Report</v-btn>
    </div>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        @hide="hideImage"
    >

    </vue-easy-lightbox>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        width="1200"
    >
      <TripDetails :tripId="tripId" v-if="drawer"></TripDetails>
    </v-navigation-drawer>

    <v-dialog
        v-model="tollDialog"
        width="650"
    >
      <TollAdd :tripId="bigId" @insert-toll="insertToll" v-if="tollDialog"></TollAdd>
    </v-dialog>

    <TripSheetUpload :trip="tripDetails" :open="tripSheetDialog" v-if="tripSheetDialog" @on-cancel="cancelTripSheet"
                     @on-upload="updateTrip"></TripSheetUpload>

    <v-dialog
        v-model="startDialog"
        width="650"
        persistent
    >
      <StartEndDialog :status="status" v-if="startDialog" :bigId="statusBigId"
                      @close-dialog="closeDialog"></StartEndDialog>
    </v-dialog>

    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import * as moment from 'moment'
import TripDetails from "@/components/Trips/trip-details/TripDetails";
import VueExcel from "@/components/common/VueExcel";
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import TollAdd from "@/components/Trips/TollAdd";
import Confirm from "@/components/common/Confirm";
import TripSheetUpload from "@/components/Trips/trip-details/user-driver-data/TripSheetUpload";
import StartEndDialog from "@/components/Trips/StartEndDialog";
import CancelTripSheet from "@/components/Trips/trip-details/user-driver-data/CancelTripSheet";
import ReceiptView from "@/components/Trips/ReceiptView";

export default {
  name: "TripsView",
  components: {ReceiptView, CancelTripSheet, TripSheetUpload, Confirm, TollAdd, Loading, TripDetails, StartEndDialog},
  props: {
    tripList: Array,
  },
  data: () => {
    return {
      visible: false,
      images: [],

      drawer: false,
      tripId: 0,
      openIndex: 0,

      loading: false,
      tollDialog: false,
      bigId: 0,

      tripSheetDialog: false,
      tripDetails: '',

      startDialog: false,
      status: '',
      statusBigId: 0,

      elementHeight: 0,
    }
  },
  methods: {
    tripAction(data,index,status) {
      this.startDialog = true
      this.statusBigId = data.bigId
      this.status = status
    },
    uploadTripSheet(trip) {
      console.log(this.tripSheetDialog)
      this.tripDetails = trip
      this.tripSheetDialog = true
    },
    cancelTripSheet() {
      this.tripSheetDialog = false
    },

    updateTrip() {
      this.tripSheetDialog = false
      this.$emit('on-upload');
      this.getElementHeight();
    },

    onCancel() {
      this.$emit('on-upload');
      this.getElementHeight();
    },

    getElementHeight() {
      this.elementHeight = this.$refs.element.offsetHeight;
    },
    closeDialog() {
      this.startDialog = false
    },


    getDateTime(data) {
      return data ? moment(data).format('DD-MM-YYYY LT') : ''
    },
    getTotalTime(time) {
      let h = Math.floor(time / 60);
      let m = time % 60;
      h = h < 10 ? '' + h : h;
      m = m < 10 ? '' + m : m;
      return `${h} hrs ${m.toString().substring(0, 2)} mins`;
    },
    imageView(data) {
      this.visible = true
      this.images = []
      if (data.end !== '') {
        this.images = [{src: data.startImage, title: data.start}, {src: data.endImage, title: data.end}]
      } else {
        this.images = [{src: data.startImage, title: data.start}]
      }

    },
    imageViewEnd(data) {
      this.visible = true
      this.images = [{src: data.endImage, title: data.end}, {src: data.startImage, title: data.start}]
    },
    hideImage() {
      this.visible = false
    },
    openDrawer(id) {
      this.drawer = true
      this.tripId = id
    },
    viewTollDialog(data) {
      this.tollDialog = true
      this.bigId = data.bigId
    },
    exportReport() {
      try {
        let tripData = []
        let header = {
          tripId: 'Trip Id',
          user: 'User',
          userMobile: 'User Number',
          city: 'City',
          branch: 'Branch',
          driver: 'Driver',
          driverNumber: 'Driver Number',
          vehicleNo: 'Vehicle Number',
          sDate: 'Start Date',
          eDate: 'End Date',
          travelTime: 'Travel Time',
          startReading: 'Start Reading',
          endReading: 'End Reading',
          travelKm: 'Travel KM',
          toll: 'Toll',
          totalAmount: 'Amount'

        }
        tripData.push(header)
        let total = 0
        this.tripList.map(data => {
          let hour = Math.floor(data.driverCalculation.travelTime / 60);
          let minute = data.driverCalculation.travelTime % 60;
          hour = hour < 10 ? '' + hour : hour;
          minute = minute < 10 ? '' + minute : minute;
          let time = `${hour} hrs ${minute} mins`;
          let toll = 0
          if (data.totalAmount !== '') {
            total += parseFloat(data.totalAmount)
          }
          if (data.toll.length > 0) {
            data.toll.map((item) => {
              if (item.status === 1) {
                toll += item.amount
              }
            })
          }
          let object = {
            tripId: data.id,
            user: data.user.name,
            userMobile: data.user.mobile.toString(),
            city: data.city.name,
            branch: data.user.branch,
            driver: data.driver.name,
            driverNumber: data.driver.mobile.toString(),
            vehicleNo: data.driver.vehicle,
            sDate: moment(data.sTime).format('DD-MM-YYYY LT'),
            eDate: moment(data.eTime).format('DD-MM-YYYY LT'),
            travelTime: data.status.id === 10 || data.status.id === 9 ? time : '__________',
            startReading: data.readings.start,
            endReading: data.status.id === 10 || data.status.id === 9 ? data.readings.end : '__________',
            travelKm: data.status.id === 10 || data.status.id === 9 ? data.driverCalculation.travelKM : '__________',
            toll: data.status.id === 10 || data.status.id === 9 ? toll : '__________',
            totalAmount: data.totalAmount,
          }
          tripData.push(object)
        })

        tripData.push({})
        tripData.push({toll: 'Total Amount', totalAmount: total})
        VueExcel.methods.downloadExcelSheet(tripData, 'Report', {skipHeader: true})
      } catch (e) {
        console.log(e)
      }
    },
    async cancelToll(data, toll) {
      let confirmTitle = "Confirm Cancel";
      let confirmText = "Please confirm to Cancel this Toll";
      let confirmIcon = "fas fa-exclamation";
      if (await this.$refs.confirm.open(confirmTitle, confirmText, {
        icon: confirmIcon,
        color: 'red',
      })) {
        this.loading = true
        try {
          const index = this.tripList.findIndex(trip => trip.id === data.id)
          if (index !== -1) {
            const tollIndex = this.tripList[index].toll.findIndex(item => item.id === toll.id)
            if (tollIndex !== -1) {
              this.tripList[index].toll.splice(tollIndex, 1)
            }
          }
          const response = await axios.post('/backend/tollRemove', {
            tripId: data.bigId,
            toll: this.tripList[index].toll,
            tollId: toll.id
          })
          console.log(response)
          if (response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
          } else if (response.status === 206) {
            this.loading = false
            this.$toasted.show(response.data, {
              position: "bottom-center",
              duration: 4000
            });
          }
        } catch (error) {
          this.loading = false
        }
      }
    },
    insertToll(data) {
      console.log(data)
      this.tollDialog = false
      const index = this.tripList.findIndex(trip => trip.bigId === this.bigId)
      if (index !== -1) {
        this.tripList[index].toll.unshift(data)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./tripView";
</style>