<template>
  <div class="main-inner-blk">
    <v-card class="assign-filter-blk" elevation="3">
      <Filters @get-filter="getFilters" :filterData="'toBeAssigned'" :placeHolder="'Search ID/User'"></Filters>
    </v-card>
    <div class="trip-list-blk">
      <div v-if="tripList.length > 0">
        <div class="trip-list-header-blk">
          <span class="sl-no-blk header-content-blk">Sl No</span>
          <span class="id-blk header-content-blk"> Trip ID</span>
          <span class="user-blk header-content-blk">User</span>
          <span class="corporate-blk header-content-blk">Corporate</span>
          <span class="time-blk header-content-blk">PickUp</span>
          <span class="category-blk header-content-blk">Category</span>
          <span class="details-blk header-content-blk">Assign Details</span>
          <span class="driver-blk ">Driver</span>
          <span class="reject-blk"><v-btn small color="green" title="Download Excel" @click="exportExcel"><i class="fas fa-download"></i></v-btn></span>
        </div>
        <div class="trip-content-blk" v-for="(data,index) in tripList" :key="index">
          <span class="sl-no-blk list-content-blk">{{ index + 1 }}</span>
          <span class="id-blk list-content-blk id-content-blk" @click="showDetails(data.id)">{{ data.id }}</span>
          <div class="user-blk content-blk list-content-blk">
            <span>{{ data.user }}</span>
            <span class="">{{ data.mobile }}</span>
            <v-tooltip bottom color="#b02604" max-width="400" >
              <template v-slot:activator="{ on, attrs }">
            <span class="source-blk"  v-bind="attrs"
                  v-on="on">{{data.source}}</span>
              </template>
              <span>{{data.source}}</span>
            </v-tooltip>
          </div>
          <div class="corporate-blk content-blk list-content-blk">
            <span>{{ data.corporate }}</span>
            <span>{{ data.branch }}</span>
          </div>
          <div class="time-blk content-blk list-content-blk">
            <span>{{ getTime(data.pickUpTime) }}</span>
            <span>{{ getTimer(data.pickUpTime) }}</span>
          </div>
          <span class="category-blk list-content-blk">{{ data.category }}</span>
          <div class="details-blk content-blk list-content-blk">
          <span
              v-if="data.driverDetails !== null">{{ data.driverDetails.name }} - {{ data.driverDetails.mobile }} - {{
              data.vehicle
            }}</span>
          </div>
          <div class="driver-blk">
            <v-text-field
                v-model="data.driver"
                placeholder="Driver"
                label="Driver"
                dense
                outlined
                hide-details
                @input="getDriverDetails(data,index)"
                maxlength="10"
            ></v-text-field>
          </div>
          <div class="reject-blk">
            <!--            <v-btn color="red" text small title="Reject Trip" @click="rejectTrip(data)"><i class="fas fa-times"></i></v-btn>-->
            <v-btn color="green" text small title="Copy" @click="copyMessage(data)" class="whatsapp-blk"><i
                class="fab fa-whatsapp-square"></i></v-btn>
          </div>
        </div>
      </div>
      <div v-else class="no-trip-blk">
        <span>No Trips To Assign</span>
      </div>
    </div>
    <v-dialog
        v-model="assignDialog"
        width="800"
        persistent
    >
      <v-card>
        <DriverAssign :driverDetails="driverDetails" :bigId="bigId" @close-dialog="closeAssignDialog"
                      v-if="assignDialog" :driverNumber="driverNumber" @assign-driver="driverAssign" :modelList="modelList" :currentCity="currentCity" :currentState="currentState"></DriverAssign>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="addDialogStatus"
        width="800"
        persistent
    >
      <v-card>
        <DriverCabInsert @close-add-dialog="closeAddDialog" :driverNumber="driverNumber" :bigId="bigId"
                         @assign-driver="driverCabAssign" :name="driverName"
                         :disableStatus="disableStatus" :modelList="modelList" :currentCity="currentCity" :currentState="currentState" v-if="addDialogStatus"></DriverCabInsert>
      </v-card>
    </v-dialog>
    <Loading v-if="loading"></Loading>

    <v-navigation-drawer
        v-model="drawer"
        temporary
        width="1200"
        absolute
    >
      <TripDetails :tripId="tripId" v-if="drawer"></TripDetails>
    </v-navigation-drawer>

    <v-dialog
        width="500"
        v-model="dialogView"
    >
      <v-card class="card-view-modal">
        <span>Driver exists for another vendor, please contact taSki to assign the trip to this driver</span>
        <div class="card-button-blk">
          <v-btn small color="#eea004" @click="closeVendorDialog">OK</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import axios from "@/axios/axios-global";
import axiosPublic from "axios";
import * as moment from "moment";
import DriverAssign from "@/components/assign/driver-assign/DriverAssign";
import DriverCabInsert from "@/components/assign/driver-cab-insert/DriverCabInsert";
import Loading from "@/components/common/Loading";
import TripDetails from "@/components/Trips/trip-details/TripDetails";
import Confirm from "@/components/common/Confirm";
import VueExcel from "@/components/common/VueExcel";

export default {
  name: "ToBeAssigned",
  components: {Confirm, TripDetails, Loading, DriverCabInsert, DriverAssign, Filters},
  data: () => {
    return {
      tripList: [],

      tripId: 0,
      tripIndex: '',

      assignDialog: false,
      driverDetails: '',
      addDialogStatus: false,
      driverNumber: '',
      bigId: '',
      driverName: '',
      disableStatus: false,
      dialogView: false,

      loading: false,

      drawer: false,

      date: moment().format('YYYY-MM-DD'),
      timeout: '',

      // oldTripCount: 0,
      // newTripCount: 0,
      // differenceCount: 0,

      modelList: [],
      currentCity: 0,
      currentState: 0,

      filters: '',
    }
  },
  mounted() {
    this.getTripData({date: this.date});
    this.getAssignFilters()
  },
  methods: {
    getFilters(data) {
      this.filters = data
      this.getTripData(this.filters)
      this.$emit('get-all-filters',this.filters)
    },
    async getAssignFilters() {
      const response = await axios.get('/backend/modelList')
      console.log(response)
      this.modelList = response.data
    },
    async getTripData(data) {
      // this.newTripCount = 0
      // this.differenceCount = 0
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.loading = true
        const response = await axios.post('/backend/tripsToAssign', {
          state: data.state,
          search: data.searchValue,
          city: data.city,
          date: data.date
        })
        console.log(response)
        this.loading = false
        this.tripList = response.data.data
        this.tripList = this.tripList.map(data => {
          return {...data, driver: ''}
        })
        if (this.tripList.length > 0) {
          // this.oldTripCount = this.tripList.length
          this.sortData(this.tripList)
        }
        // setTimeout(() => {
        //   this.getTripCount(this.filters)
        // }, 60000)
      }, 500)
    },
    // async getTripCount(data) {
    //   const response = await axios.post('/backend/tripCount', {
    //     state: data.state,
    //     search: data.searchValue,
    //     city: data.city,
    //     date: data.date
    //   })
    //   this.newTripCount = response.data.tripCount
    //   if (this.newTripCount - this.oldTripCount > 0) {
    //     this.differenceCount = this.newTripCount - this.oldTripCount
    //   } else if (this.newTripCount === this.oldTripCount) {
    //     setTimeout(() => {
    //       this.getTripCount(data)
    //     }, 60000)
    //   }
    // },
    // async rejectTrip(data) {
    //   let confirmTitle = "Confirm Reject";
    //   let confirmText = "Please confirm to Reject this Trip";
    //   let confirmIcon = "fas fa-exclamation";
    //   if (await this.$refs.confirm.open(confirmTitle, confirmText, {
    //     icon: confirmIcon,
    //     color: 'red',
    //   })) {
    //     this.loading = true
    //     try {
    //       let tripArray = []
    //       tripArray.push(data.bigId)
    //       const response = await axios.patch('/backend/vendorReject', {tripId: tripArray})
    //       if (response.status === 200) {
    //         this.loading = false
    //         this.$toasted.show(response.data, {
    //           position: "bottom-center",
    //           duration: 4000
    //         });
    //         const index = this.tripList.findIndex(item => item.id === data.id)
    //         if (index !== -1) {
    //           this.tripList.splice(index, 1)
    //         }
    //         this.sortData(this.tripList)
    //         this.oldTripCount = this.tripList.length
    //       }
    //     } catch (error) {
    //       this.loading = false
    //       this.$toasted.show(error.response.data, {
    //         position: "bottom-center",
    //         duration: 4000
    //       });
    //     }
    //   }
    // },
    copyMessage(data) {
      let text = ''
      if (data.driverDetails !== null) {
        text = `Customer Name: ${data.user}\nMobile: ${data.mobile}\nLocation: ${data.source}\nTime: ${moment(data.pickUpTime, 'x').format('DD-MM-YYYY LT')}\nBranch: ${data.branch}\nDriver: ${data.driverDetails.name}\nDriver Mobile: ${data.driverDetails.mobile}\nCar No.: ${data.vehicle}`
      } else {
        text = `Customer Name: ${data.user}\nMobile: ${data.mobile}\nLocation: ${data.source}\nTime: ${moment(data.pickUpTime, 'x').format('DD-MM-YYYY LT')}\nBranch: ${data.branch}`
      }
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$toasted.show('Details copied', {
        position: "bottom-center",
        duration: 3000
      });
    },
    reloadData() {
      if (this.filters === '') {
        this.getTripData({date: this.date});
      } else {
        this.getTripData(this.filters)
      }
    },
    getTime(data) {
      return moment(data, 'x').format('MMM DD')
    },
    getTimer(data) {
      return moment(data, 'x').format('LT')
    },
    sortData(data) {
      data.sort((a, b) => {
        return (a.driverDetails !== null) - (b.driverDetails !== null) || -(a > b) || +(a < b);
      })
    },
    async getDriverDetails(data, index) {
      if (data.driver.length === 10) {
        this.tripId = data.id
        this.tripIndex = index
        this.bigId = data.bigId
        const response = await axios.post('/backend/driverDetails', {mobile: data.driver, tripId: data.bigId})
        console.log(response)
        if (response.status === 200) {
          this.driverNumber = data.driver
          if (response.data.message !== 'No driver exist' && response.data.message !== 'No vehicle assigned') {
            this.assignDialog = true
            this.driverDetails = response.data.driverDetails
            this.currentCity = response.data.currentCity
            this.currentState = response.data.currentState
          } else if (response.data.message === 'No vehicle assigned') {
            this.addDialogStatus = true
            this.disableStatus = true
            this.driverName = response.data.driverDetails.name
            this.currentCity = response.data.currentCity
            this.currentState = response.data.currentState
          } else {
            this.addDialogStatus = true
            this.disableStatus = false
            this.currentCity = response.data.currentCity
            this.currentState = response.data.currentState
            this.driverName = ''
          }
        } else if (response.status === 206) {
          this.dialogView = true
          this.driverNumber = data.driver

        }
      }
    },
    exportExcel() {
      console.log(this.tripList)
      let trips = []
      trips.push({
        id: 'Trip Id',
        user: 'Customer Name',
        userMobile: 'Customer Number',
        pickUpTime: 'Pickup Time',
        location: 'Location',
        driver: 'Driver Name',
        driverMobile: 'Driver Number',
        vehicle: 'Vehicle Number'
      })
      this.tripList.map(trip => {
        let object = {
          id: trip.id,
          user: trip.user,
          userMobile: trip.mobile,
          location: trip.source,
          pickUpTime: moment(trip.pickUpTime,'x').format('DD-MM-YYYY LT'),
          driver: '',
          driverMobile: '',
          vehicle: '',
        }
        if(trip.driverDetails === null) {
          trips.push(object)
        }
      })
      VueExcel.methods.downloadExcelSheet(trips, 'To be Assigned Details', {skipHeader: true})
    },
    showDetails(data) {
      this.drawer = true
      this.tripId = data
    },
    driverAssign(data) {
      this.tripList[this.tripIndex].driverDetails = {name: data.name, mobile: data.number}
      this.tripList[this.tripIndex].vehicle = data.vehicleNo
      this.assignDialog = false
      this.tripList[this.tripIndex].driver = ''
      this.sortData(this.tripList)
    },
    driverCabAssign(data) {
      this.tripList[this.tripIndex].driverDetails = {name: data.name, mobile: data.number}
      this.tripList[this.tripIndex].vehicle = data.vehicleNo
      this.addDialogStatus = false
      this.tripList[this.tripIndex].driver = ''
      this.sortData(this.tripList)
    },
    closeAssignDialog() {
      this.assignDialog = false
      this.tripList[this.tripIndex].driver = ''
    },
    closeAddDialog() {
      this.addDialogStatus = false
      this.tripList[this.tripIndex].driver = ''
    },
    closeVendorDialog() {
      this.dialogView = false
      const index = this.tripList.findIndex(data => data.driver === this.driverNumber)
      if(index !== -1) {
        this.tripList[index].driver = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./tobeAssigned.scss";
</style>