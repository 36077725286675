<template>
<div class="document-insert-content-modal">
    <!-- <span class="insert-header-blk">Documents</span> -->
    <div class="document-upload-modal">
        <v-card class="photo-insert-modal" elevation="2">
            <input accept="image/*" ref="photoUpload" @change="insertPhoto" type="file" class="input-blk">
            <div class="image-upload-modal" @click="$refs.photoUpload.click()" v-if="rcBook === ''">
                <i class="fas fa-images"></i>
                <span>Upload Image</span>
            </div>
            <div class="image-upload-modal" v-if="rcBook !== ''">
                <img :src="`${rcBook}?t=${new Date().getTime()}`" class="image-view-blk">
            </div>
            <div class="image-name-blk">
                <span>RC Book</span>
                <v-btn small color="#83b9e5" @click="$refs.photoUpload.click()" v-if="rcBook !== ''">Edit<i class="fas fa-pen"></i></v-btn>
            </div>
        </v-card>
        <v-card class="photo-insert-modal" elevation="2">
            <input accept="image/*" ref="idProof" @change="insertIdProof" type="file" class="input-blk">
            <div class="image-upload-modal" @click="$refs.idProof.click()" v-if="exteriorImage === ''">
                <i class="fas fa-images"></i>
                <span>Upload Image</span>
            </div>
            <div class="image-upload-modal" v-if="exteriorImage !== ''">
                <img :src="`${exteriorImage}?t=${new Date().getTime()}`" class="image-view-blk">
            </div>
            <div class="image-name-blk">
                <span>Vehicle Exterior</span>
                <v-btn small color="#83b9e5" @click="$refs.idProof.click()" v-if="exteriorImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
            </div>
        </v-card>

        <v-card class="photo-insert-modal" elevation="2">
            <input accept="image/*" ref="licenceFront" @change="insertLicenceFront" type="file" class="input-blk">
            <div class="image-upload-modal" @click="$refs.licenceFront.click()" v-if="interiorImage === ''">
                <i class="fas fa-images"></i>
                <span>Upload Image</span>
            </div>
            <div class="image-upload-modal" v-if="interiorImage !== ''">
                <img :src="`${interiorImage}?t=${new Date().getTime()}`" class="image-view-blk">
            </div>
            <div class="image-name-blk">
                <span>Vehicle Interior</span>
                <v-btn small color="#83b9e5" @click="$refs.licenceFront.click()" v-if="interiorImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
            </div>
        </v-card>
        <v-card class="photo-insert-modal" elevation="2">
            <input accept="image/*" ref="licenceBack" @change="insertLicenceBack" type="file" class="input-blk">
            <div class="image-upload-modal" @click="$refs.licenceBack.click()" v-if="insuranceImage === ''">
                <i class="fas fa-images"></i>
                <span>Upload Image</span>
            </div>
            <div class="image-upload-modal" v-if="insuranceImage !== ''">
                <img :src="`${insuranceImage}?t=${new Date().getTime()}`" class="image-view-blk">
            </div>
            <div class="image-name-blk">
                <span>Commercial Insurance</span>
                <v-btn small color="#83b9e5" @click="$refs.licenceBack.click()" v-if="insuranceImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
            </div>
        </v-card>

        <v-card class="photo-insert-modal" elevation="2">
            <input accept="image/*" ref="pollutionCertificate" @change="insertpollutionCertificate" type="file" class="input-blk">
            <div class="image-upload-modal" @click="$refs.pollutionCertificate.click()" v-if="pollutionImage === ''">
                <i class="fas fa-images"></i>
                <span>Upload Image</span>
            </div>
            <div class="image-upload-modal" v-if="pollutionImage !== ''">
                <img :src="`${pollutionImage}?t=${new Date().getTime()}`" class="image-view-blk">
            </div>
            <div class="image-name-blk">
                <span>Vehicle Pollution</span>
                <v-btn small color="#83b9e5" @click="$refs.pollutionCertificate.click()" v-if="pollutionImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
            </div>
        </v-card>

        <v-card class="photo-insert-modal" elevation="2">
            <input accept="image/*" ref="policeClearance" @change="insertPoliceClearance" type="file" class="input-blk">
            <div class="image-upload-modal" @click="$refs.policeClearance.click()" v-if="carriagepermitImage === ''">
                <i class="fas fa-images"></i>
                <span>Upload Image</span>
            </div>
            <div class="image-upload-modal" v-if="carriagepermitImage !== ''">
                <img :src="`${carriagepermitImage}?t=${new Date().getTime()}`" class="image-view-blk">
            </div>
            <div class="image-name-blk">
                <span>Carriage Permit</span>
                <v-btn small color="#83b9e5" @click="$refs.policeClearance.click()" v-if="carriagepermitImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
            </div>
        </v-card>
    </div>
</div>
</template>

<script>
import axios from "@/axios/axios-global";

export default {
    name: "DocumentInsert",
    data: () => {
        return {
            rcBook: '',
            exteriorImage: '',
            // panImage: '',
            interiorImage: '',
            insuranceImage: '',
            pollutionImage: '',
            carriagepermitImage: '',
        }
    },
    props: {
        vehicleId: String,
        licenceNo: String,
        expDate: String,
        document: [Object, String],
    },
    mounted() {
        // console.log(this.vehicleId)
        console.log('sample1')
        this.rcBook = this.document.rcbook.image
        this.exteriorImage = this.document.exterior.image
        this.interiorImage = this.document.interior.image
        this.insuranceImage = this.document.insurance.image
        this.pollutionImage = this.document.pollution.image
        this.carriagepermitImage = this.document.carriagepermit.image
    },
    watch: {
        vehicleId() {
            console.log('vehicleID')
            this.rcBook = this.document.rcbook.image
            this.exteriorImage = this.document.exterior.image
            this.interiorImage = this.document.interior.image
            this.insuranceImage = this.document.insurance.image
            this.pollutionImage = this.document.pollution.image
            this.carriagepermitImage = this.document.carriagepermit.image
        }
    },
    methods: {
        isImage(file) {
            return file && file['type'].split('/')[0] === 'image';
        },

        async insertPhoto(event) {
            let files = event.target.files[0];
            if (this.isImage(files)) {
                // this.rcBook = URL.createObjectURL(files)
                // if(event.target.files.length > 0) {
                let formData = new FormData();
                const payload = {
                    vehicleId: this.vehicleId,
                    imageName: 'rcBook'
                }
                formData.append('data', JSON.stringify(payload))
                formData.append('image', files)
                try {
                    const response = await axios.patch('/backend/updateVehicleDocument', formData)
                    console.log(response)

                    if (response.status === 200) {
                        this.rcBook = ''
                        // this.rcBook = response.data.url + 'sdsd'
                        this.$toasted.show(response.data.message, {
                            position: "bottom-center",
                            duration: 4000
                        });
                        this.$emit('update-image');
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            } else {
                this.$toasted.show('Please select an image file', {
                    position: "bottom-center",
                    duration: 4000
                });
            }

        },
        async insertIdProof(event) {
            let files = event.target.files[0];
            if (this.isImage(files)) {
                // this.exteriorImage = URL.createObjectURL(files)
                // if (event.target.files.length > 0) {
                let formData = new FormData();
                const payload = {
                    vehicleId: this.vehicleId,
                    imageName: 'exterior'
                }
                formData.append('data', JSON.stringify(payload))
                formData.append('image', files)
                try {
                    const response = await axios.patch('/backend/updateVehicleDocument', formData)
                    console.log(response)
                    if (response.status === 200) {
                        this.exteriorImage = response.data.url
                        this.$toasted.show(response.data.message, {
                            position: "bottom-center",
                            duration: 4000
                        });
                        this.$emit('update-image');
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            } else {
                this.$toasted.show('Please select an image file', {
                    position: "bottom-center",
                    duration: 4000
                });
            }
        },
        // async insertPan(event) {
        //   let files = event.target.files[0]
        //   this.panImage = URL.createObjectURL(files)
        // },
        async insertLicenceFront(event) {
            let files = event.target.files[0];
            if (this.isImage(files)) {
                // this.interiorImage = URL.createObjectURL(files)
                // if (event.target.files.length > 0) {
                let formData = new FormData();
                const payload = {
                    vehicleId: this.vehicleId,
                    imageName: 'interior',
                }
                formData.append('data', JSON.stringify(payload))
                formData.append('image', files)
                try {
                    const response = await axios.patch('/backend/updateVehicleDocument', formData)
                    console.log(response)
                    if (response.status === 200) {
                        this.interiorImage = response.data.url
                        this.$toasted.show(response.data.message, {
                            position: "bottom-center",
                            duration: 4000
                        });
                        this.$emit('update-image');
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            } else {
                this.$toasted.show('Please select an image file', {
                    position: "bottom-center",
                    duration: 4000
                });
            }
        },
        async insertLicenceBack(event) {
            let files = event.target.files[0];
            if (this.isImage(files)) {
                // this.insuranceImage = URL.createObjectURL(files)
                // if (event.target.files.length > 0) {
                let formData = new FormData();
                const payload = {
                    vehicleId: this.vehicleId,
                    imageName: 'insurance'
                }
                formData.append('data', JSON.stringify(payload))
                formData.append('image', files)
                try {
                    const response = await axios.patch('/backend/updateVehicleDocument', formData)
                    console.log(response)
                    if (response.status === 200) {
                        this.insuranceImage = response.data.url
                        this.$toasted.show(response.data.message, {
                            position: "bottom-center",
                            duration: 4000
                        });
                        this.$emit('update-image');
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            } else {
                this.$toasted.show('Please select an image file', {
                    position: "bottom-center",
                    duration: 4000
                });
            }
        },
        async insertpollutionCertificate(event) {
            let files = event.target.files[0];
            if (this.isImage(files)) {
                // if (event.target.files.length > 0) {
                let formData = new FormData();
                const payload = {
                    vehicleId: this.vehicleId,
                    imageName: 'pollution'
                }
                formData.append('data', JSON.stringify(payload))
                formData.append('image', files)
                try {
                    const response = await axios.patch('/backend/updateVehicleDocument', formData)
                    console.log(response)
                    if (response.status === 200) {
                        this.pollutionImage = response.data.url
                        this.$toasted.show(response.data.message, {
                            position: "bottom-center",
                            duration: 4000
                        });
                        this.$emit('update-image');
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            } else {
                this.$toasted.show('Please select an image file', {
                    position: "bottom-center",
                    duration: 4000
                });
            }
        },
        async insertPoliceClearance(event) {
            let files = event.target.files[0];
            if (this.isImage(files)) {
                // this.carriagepermitImage = URL.createObjectURL(files)
                // if (event.target.files.length > 0) {
                let formData = new FormData();
                const payload = {
                    vehicleId: this.vehicleId,
                    imageName: 'carriagePermit'
                }
                formData.append('data', JSON.stringify(payload))
                formData.append('image', files)
                try {
                    const response = await axios.patch('/backend/updateVehicleDocument', formData)
                    console.log(response)
                    if (response.status === 200) {
                        this.carriagepermitImage = response.data.url
                        this.$toasted.show(response.data.message, {
                            position: "bottom-center",
                            duration: 4000
                        });
                        this.$emit('update-image');
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            } else {
                this.$toasted.show('Please select an image file', {
                    position: "bottom-center",
                    duration: 4000
                });
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "./documentInsert";
</style>
