<template>
  <div class="receipt-container">
    <div class="receipt-list">
      <div class="receipt-blk" v-for="(receipt,index) in trip.parking" :key="index">
        <v-chip color="green" label small v-if="receipt.status === 1" title="Approved">
        <span>₹ {{ receipt.amount }}</span>
        </v-chip>
        <v-chip v-if="receipt.status === 0" color="orange" label small title="Waiting for approval">
          <span>₹ {{ receipt.amount }}</span>
        </v-chip>
        <v-chip label small color="red" v-if="receipt.status === 2" title="Rejected">
          <span>₹ {{ receipt.amount }}</span>
        </v-chip>
      </div>
    </div>
    <v-btn x-small color="yellow" @click="addReceipt" v-if="trip.verified !== 1">Add Receipt</v-btn>

    <v-dialog
        v-model="dialog"
        width="700"
    >
      <ReceiptInsertDialog v-if="dialog" @insert-receipt="insertReceipt" :tripId="trip.bigId"></ReceiptInsertDialog>
    </v-dialog>
  </div>
</template>

<script>
import ReceiptInsertDialog from "@/components/Trips/ReceiptInsertDialog";
export default {
  name: "ReceiptView",
  components: {ReceiptInsertDialog},
  props: {
    trip: Object,
  },
  data: ()=> {
    return {
      dialog: false,
    }
  },
  methods: {
    addReceipt() {
      this.dialog = true
    },
    insertReceipt() {
      this.dialog = false
    }
  },
}
</script>

<style scoped lang="scss">
.receipt-container {
  .receipt-list {
    display: flex;
    flex-wrap: wrap;
    .receipt-blk {
      display: flex;
      ::v-deep .v-chip {
        color: white;
        margin-bottom: 5px;
        margin-right: 5px;
        font-weight: bold;
      }
    }

  }


}
</style>