<template>
  <v-card class="vehicle-bulk-modal">
    <div class="bulk-modal">
      <div class="button-header">
        <div>
          <v-btn color="#087c0d" @click="uploadExcel" v-if="buttonCheck === false">Upload</v-btn>
          <v-btn color="red" @click="cancelUpload">Cancel</v-btn>
        </div>
        <span v-if="buttonCheck === true" class="error-blk"><i class="fas fa-exclamation"></i>Some Details are missing, fill the red marked columns correctly and upload</span>
      </div>
      <div class="bulk-content-modal">
        <div class="content-header">
          <span class="header font-blk">Vehicle Number</span>
<!--          <span class="header font-blk">Manufacturer</span>-->
<!--          <span class="header font-blk">Model</span>-->
<!--          <span class="header font-blk">Color</span>-->
          <span class="header font-blk">Year</span>
        </div>

        <div class="bulk-content">
          <div class="driver-bulk-content" v-for="(data,index) in vehicleData" :key="index">
            <span class="header vehicle-no" :class="{'no-data':!data.vehicleNo}">{{ data.vehicleNo }}</span>
<!--            <span class="header" :class="{'no-data':!data.manufacturer}">{{ data.manufacturer }}</span>-->
<!--            <span class="header" :class="{'no-data':!data.model}">{{ data.model }}</span>-->
<!--            <span class="header" :class="{'no-data':!data.color}">{{ data.color }}</span>-->
            <span class="header" :class="{'no-data':!data.year || data.year.length !== 4}">{{ data.year }}</span>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </v-card>

</template>

<script>
import Loading from "@/components/common/Loading";
import axios from "@/axios/axios-global";

export default {
  name: "VehicleBulkUpload",
  components: {Loading},
  props: {
    vehicleData: Array,
  },
  data: () => {
    return {
      buttonCheck: false,
      oldListLength: 0,
      newListLength: 0,

      newVehicleData: [],

      loading: false,
    }
  },
  mounted() {
    this.oldListLength = this.vehicleData.length
    this.vehicleData.map(vehicle => {
      if (vehicle.vehicleNo && vehicle.manufacturer && vehicle.model && vehicle.color && vehicle.year && vehicle.year.length === 4) {
        this.newVehicleData.push({
          vehicleNo: vehicle.vehicleNo,
          manufacturer: {
            id: 1,
            name: vehicle.manufacturer
          },
          model: {
            id: 1,
            name: vehicle.model
          },
          color: {
            id: 1,
            name: vehicle.color,
          },
          year: vehicle.year
        })
      }
    })
    console.log(this.newVehicleData)
    this.newListLength = this.newVehicleData.length
    if (this.newListLength !== this.oldListLength) {
      this.buttonCheck = true
    }
  },
  methods: {
    async uploadExcel() {
      this.loading = true
      try {
        const response = await axios.post('/backend/vehicleInsert', {vehicle: this.newVehicleData})
        if (response.status === 200) {
          this.loading = false
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000
          });
          this.$emit('vehicle-bulk',{
            idArray: response.data.id,
            array: this.newVehicleData,
          })
        } else if (response.status === 206) {
          this.loading = false
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000
          });
        }
      } catch (error) {

      }
    },
    cancelUpload() {
      this.loading = false
      this.$toasted.show(error.response.data, {
        position: "bottom-center",
        duration: 4000
      });
    }
  },
}
</script>

<style scoped lang="scss">
.vehicle-bulk-modal {
  padding: 10px;

  .bulk-modal {
    display: flex;
    flex-direction: column;

    .button-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 15px;

      .v-btn {
        margin: 0 5px;
      }

      .error-blk {
        color: red;
        font-weight: bold;

        .fas {
          margin-right: 3px;
        }
      }
    }

    .bulk-content-modal {
      //padding:0  10px 10px  10px;
      max-height: calc(100vh - 300px);
      overflow-y: scroll;

      .content-header {
        display: flex;
        width: 100%;
        border: 1px solid gray;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;

        .header {
          width: 20%;
          padding: 5px;
          border-right: 1px solid gray;
        }

        .font-blk {
          font-weight: bold;
          color: #6a0737;
        }

        :last-child {
          border-right: none;
        }
      }

      .bulk-content {
        display: flex;
        flex-direction: column;
        border: 1px solid gray;
        border-top: none;
        border-bottom: none;

        .driver-bulk-content {
          display: flex;
          width: 100%;
          border-bottom: 1px solid gray;

          .header {
            width: 20%;
            padding: 5px;
            border-right: 1px solid gray;
            word-break: break-all;
            text-transform: capitalize;
          }

          .vehicle-no {
            text-transform: uppercase;
          }

          .no-data {
            background-color: #f15d5d;
          }

          :last-child {
            border-right: none;
          }
        }
      }
    }
  }
}
</style>