<template>
  <div class="document-insert-content-modal">
    <span class="insert-header-blk">Documents</span>
    <div class="document-upload-modal">
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="photoUpload" @change="insertPhoto" type="file" class="input-blk">
        <div class="image-upload-modal" @click="$refs.photoUpload.click()" v-if="photoImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="photoImage !== ''">
          <img :src="`${photoImage}?t=${new Date().getTime()}`" class="image-view-blk">
        </div>
        <div class="image-name-blk">
          <span>Photo</span>
          <v-btn small color="#83b9e5" @click="$refs.photoUpload.click()" v-if="photoImage !== ''">Edit<i
              class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="idProof" @change="insertIdProof" type="file" class="input-blk">
        <div class="image-upload-modal" @click="$refs.idProof.click()" v-if="idImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="idImage !== ''">
          <img :src="`${idImage}?t=${new Date().getTime()}`" class="image-view-blk">
        </div>
        <div class="image-name-blk">
          <span>ID Proof</span>
          <v-btn small color="#83b9e5" @click="$refs.idProof.click()" v-if="idImage !== ''">Edit<i
              class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <!--      <v-card class="photo-insert-modal" elevation="2" >-->
      <!--        <input accept="image/*" ref="panProof" @change="insertPan" type="file" class="input-blk">-->
      <!--        <div class="image-upload-modal" @click="$refs.panProof.click()" v-if="panImage === ''">-->
      <!--          <i class="fas fa-images"></i>-->
      <!--          <span>Upload Image</span>-->
      <!--        </div>-->
      <!--        <div class="image-upload-modal" v-if="panImage !== ''">-->
      <!--          <img :src="panImage" class="image-view-blk">-->
      <!--        </div>-->
      <!--        <span class="image-name-blk">Pan Card</span>-->
      <!--      </v-card>-->
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="licenceFront" @change="insertLicenceFront" type="file" class="input-blk">
        <div class="image-upload-modal" @click="$refs.licenceFront.click()" v-if="licenceFrontImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="licenceFrontImage !== ''">
          <img :src="`${licenceFrontImage}?t=${new Date().getTime()}`" class="image-view-blk">
        </div>
        <div class="image-name-blk">
          <span>Licence Front</span>
          <v-btn small color="#83b9e5" @click="$refs.licenceFront.click()" v-if="licenceFrontImage !== ''">Edit<i
              class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="licenceBack" @change="insertLicenceBack" type="file" class="input-blk">
        <div class="image-upload-modal" @click="$refs.licenceBack.click()" v-if="licenceBackImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="licenceBackImage !== ''">
          <img :src="`${licenceBackImage}?t=${new Date().getTime()}`" class="image-view-blk">
        </div>
        <div class="image-name-blk">
          <span>Licence Back</span>
          <v-btn small color="#83b9e5" @click="$refs.licenceBack.click()" v-if="licenceBackImage !== ''">Edit<i
              class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="policeClearance" @change="insertPoliceClearance" type="file" class="input-blk">
        <div class="image-upload-modal" @click="$refs.policeClearance.click()" v-if="policeClearanceImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="policeClearanceImage !== ''">
          <img :src="`${policeClearanceImage}?t=${new Date().getTime()}`" class="image-view-blk">
        </div>
        <div class="image-name-blk">
          <span>Police Clearance</span>
          <v-btn small color="#83b9e5" @click="$refs.policeClearance.click()" v-if="policeClearanceImage !== ''">Edit<i
              class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";

export default {
  name: "DocumentInsert",
  data: () => {
    return {
      photoImage: '',
      idImage: '',
      // panImage: '',
      licenceFrontImage: '',
      licenceBackImage: '',
      policeClearanceImage: '',
    }
  },
  props: {
    driverId: String,
    licenceNo: String,
    expDate: String,
    documents: Object,
  },
  mounted() {
      console.log(this.documents)
    this.photoImage = this.documents.photo.image
    this.idImage = this.documents.idproof.image
    this.licenceFrontImage = this.documents.licensefront.image
    this.licenceBackImage = this.documents.licenseback.image
    this.policeClearanceImage = this.documents.policeclearance.image
  },
  watch: {
    driverId() {
      this.photoImage = this.documents.photo.image
      this.idImage = this.documents.idproof.image
      this.licenceFrontImage = this.documents.licensefront.image
      this.licenceBackImage = this.documents.licenseback.image
      this.policeClearanceImage = this.documents.policeclearance.image
    }
  },
  methods: {
    isImage(file) {
    return file && file['type'].split('/')[0] === 'image';
},

async insertPhoto(event) {
    let files = event.target.files[0];
    if (this.isImage(files)) {
        let formData = new FormData();
        const payload = {
            driverId: this.driverId,
            imageName: 'photo'
        };
        formData.append('data', JSON.stringify(payload));
        formData.append('image', files);
        try {
            const response = await axios.patch('/backend/driverDocumentUpdate', formData);
            if (response.status === 200) {
                this.photoImage = response.data.url;
                this.$toasted.show(response.data.message, {
                    position: "bottom-center",
                    duration: 4000
                });
                this.$emit('update-image');
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    } else {
        this.$toasted.show('Please select an image file', {
            position: "bottom-center",
            duration: 4000
        });
    }
},

    async insertIdProof(event) {
      let files = event.target.files[0];
    if (this.isImage(files)) {
      // this.idImage = URL.createObjectURL(files)
      // if(event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverId,
          imageName: 'idProof'
        };
        formData.append('data', JSON.stringify(payload));
        formData.append('image', files);
        try {
        const response = await axios.patch('/backend/driverDocumentUpdate', formData)
        console.log(response)
        if (response.status === 200) {
          this.idImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
                    duration: 4000
                });
                this.$emit('update-image');
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    } else {
        this.$toasted.show('Please select an image file', {
            position: "bottom-center",
            duration: 4000
          });
      }
    },
    // async insertPan(event) {
    //   let files = event.target.files[0]
    //   this.panImage = URL.createObjectURL(files)
    // },
    async insertLicenceFront(event) {
      let files = event.target.files[0];
    if (this.isImage(files)) {
      // this.licenceFrontImage = URL.createObjectURL(files)
      // if(event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverId,
          imageName: 'licenceFront',
          licenceNo: this.licenceNo,
          // expData: moment(this.expDate).format('YYYYMMDD'),
        }
        formData.append('data', JSON.stringify(payload))
        formData.append('image', files)
        try {
        const response = await axios.patch('/backend/driverDocumentUpdate', formData)
        console.log(response)
        if (response.status === 200) {
          this.licenceFrontImage = response.data.url
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000
          });
          this.$emit('update-image');
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    } else {
        this.$toasted.show('Please select an image file', {
            position: "bottom-center",
            duration: 4000
          });
      }
    },
    async insertLicenceBack(event) {
      let files = event.target.files[0];
    if (this.isImage(files)) {
      // this.licenceBackImage = URL.createObjectURL(files)
      // if(event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverId,
          imageName: 'licenceBack'
        }
        formData.append('data', JSON.stringify(payload))
        formData.append('image', files)
        try {
        const response = await axios.patch('/backend/driverDocumentUpdate', formData)
        console.log(response)
        if (response.status === 200) {
          this.licenceBackImage = response.data.url
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000
          });
          this.$emit('update-image');
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    } else {
        this.$toasted.show('Please select an image file', {
            position: "bottom-center",
            duration: 4000
          });
      }
    },
    async insertPoliceClearance(event) {
      let files = event.target.files[0];
    if (this.isImage(files)) {
      // this.policeClearanceImage = URL.createObjectURL(files)
      // if(event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverId,
          imageName: 'policeClearance'
        }
        formData.append('data', JSON.stringify(payload))
        formData.append('image', files)
        try {
        const response = await axios.patch('/backend/driverDocumentUpdate', formData)
        console.log(response)
        if (response.status === 200) {
          this.policeClearanceImage = response.data.url
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000
          });
          this.$emit('update-image');
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    } else {
        this.$toasted.show('Please select an image file', {
            position: "bottom-center",
            duration: 4000
          });
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "./documentInsert";
</style>