import { render, staticRenderFns } from "./TripsView.vue?vue&type=template&id=627f5511&scoped=true&"
import script from "./TripsView.vue?vue&type=script&lang=js&"
export * from "./TripsView.vue?vue&type=script&lang=js&"
import style0 from "./TripsView.vue?vue&type=style&index=0&id=627f5511&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627f5511",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VChip,VDialog,VNavigationDrawer})
